import insertErrorMessage from './formMessages'
import validateEmail from './validateEmail'

// let inputs
let form = document.querySelector('.form')
//let restBaseUrl = 'http://localhost:8080/sered-web-ui'
let restBaseUrl = 'https://api.facilestacionar.com.br'

if (form) {
  form.addEventListener('submit', event => {
    // valida form
    var isValid = true

    if (form.name.value.length <= 2) {
      form.name.classList.add('has-error')
      insertErrorMessage(form.name, 'Digite seu nome')
      isValid = false
    }

    if (!validateEmail(form.email.value)) {
      form.email.classList.add('has-error')
      insertErrorMessage(form.email, 'Digite seu e-mail')
      isValid = false
    }

    if (form.subject.value.length <= 2) {
      form.subject.classList.add('has-error')
      insertErrorMessage(form.subject, 'Digite um assunto')
      isValid = false
    }

    if (form.message.value.length <= 2) {
      form.message.classList.add('has-error')
      insertErrorMessage(form.message, 'Digite uma mensagem')
      isValid = false
    }

    if (isValid === false) {
      event.preventDefault();
    } else {
      event.preventDefault();
      sendMessage();
    }

    // remove messages
    function removeMessages() {
      let messages = document.querySelectorAll('.error')
      if (messages) {
        for (let i = 0; i < messages.length; i++) {
           messages[i].remove()
        }
      }
    }

    function sendMessage() {
      $('#submit').html('Aguarde...');
      //get input field values data to be sent to server
      var post_data = {
          'name'			: $('input[name=name]').val(), 
          'email'			: $('input[name=email]').val(), 
          'phone'			: " ", 
          'message'		: '[FALE-CONOSCO] ' + $('textarea[name=message]').val()
      };
      
      $.ajax({
        type: 'POST',
        url: restBaseUrl +'/portal/commons-wsapi/contact',
        dataType: 'json',
        data: JSON.stringify(post_data),
        success: function (data) {
          if (data.status === 'OK') {
            $('#submit').html('Enviar');
            window.alert('Sua mensagem foi enviada com sucesso. Em breve iremos entrar em contato. Obrigado!'); 
            location.reload();
          } else {
            window.alert(data.status); 
          }
        },
        error: function () {
          $('#submit').html('Enviar');
          window.alert('Ocorreu um erro ao enviar sua mensagem, por favor tente novamente mais tarde ou utilize a opção abaixo a direita de Ajuda e Comentários.'); 
        }
      });
    }
  })
}
