function setInfoSectorValues(selectedSector1, selectedStreet1, cardRules1){
    var middleWeek = document.getElementById('middleWeek') ,
        saturday = document.getElementById('saturday'),
        sunday = document.getElementById('sunday'),
        maxTimeParking = document.getElementById('maxTimeParking'),
        thereTruckArea = document.getElementById('thereTruckArea'),
        thereFreightArea = document.getElementById('thereFreightArea'),
        cardRuleApplicate = document.getElementById('cardRuleApplicate'),
        flagHoliday = document.getElementById('flagHoliday');

    //Popula informações na tabela sobre a rua selecionada
    middleWeek.innerHTML = selectedStreet1.middleWeek;
    saturday.innerHTML = selectedStreet1.saturday;
    sunday.innerHTML = selectedStreet1.sunday;
    maxTimeParking.innerHTML = selectedStreet1.maxTimeParking;
    thereTruckArea.innerHTML = (selectedStreet1.thereTruckArea == 'true') ? 'SIM' : 'NÃO' ;
    thereFreightArea.innerHTML = (selectedStreet1.thereFreightArea == 'true') ? 'SIM' : 'NÃO' ;
    flagHoliday.style.display = (selectedSector1.flagHoliday) ? 'block' : 'none';

    //Informação da regra utilizada para a rua selecionada.
    for(var i = 0; i < cardRules1.length; i++){
        var cardRule = cardRules1[i];
        if(cardRule.id == selectedStreet1.cardRuleId){
            cardRuleApplicate.innerHTML = cardRule.name;
            break;
        }
    }
}

function getInfoSectorData() {

    // var URLINFOSECTORS = 'https://api.facilestacionar.com.br/portal/commons-wsapi/place/saopaulo/info-sectors';
    var URLINFOSECTORS = 'http://localhost:8080/sered-web-ui/portal/commons-wsapi/place/saopaulo/info-sectors';

    var selectStreet = document.getElementById('via');
    var selectSector = document.getElementById('area');

    if(selectSector && selectStreet){

        $.ajax({
            url: URLINFOSECTORS,
            dataType: 'json',
            success: function(data){
                if(data && data.infoSectors && data.cardRules){
                    infoSectors = data.infoSectors;
                    cardRules = data.cardRules;

                    var i = 0;
                    var option = null;
                    //Popular Setores
                    for(i = 0; i < infoSectors.length; i++){
                        var infoSector = infoSectors[i];
                        option = document.createElement('option');
                        option.text = infoSector.name;
                        option.value = infoSector.id;
                        selectSector.add(option);
                    }

                    selectedSector = infoSectors[0];
                    selectedStreet = infoSectors[0].streets[0];

                    //Popular ruas para o setor selecionado
                    for(i = 0; i < selectedSector.streets.length; i++){
                        var street = selectedSector.streets[i];
                        option = document.createElement('option');
                        option.text = street.name;
                        selectStreet.add(option);
                    }

                    setInfoSectorValues(selectedSector, selectedStreet, cardRules);
                }
            }
        });

        //Evento para popular as ruas conforme setor
        selectSector.addEventListener('change', function(){
            var i = 0;

            //Encontra o setor selecionado
            var selectedId = $('#area').find(':selected')[0].value;
            for(i = 0; i < infoSectors.length; i++){
                var infoSector = infoSectors[i];
                if(infoSector.id == selectedId){
                    selectedSector = infoSector;
                    break;
                }
            }

            //Remove ruas anteriores
            while (selectStreet.options.length > 0) {
                selectStreet.remove(0);
            }

            //Popular ruas para o setor selecionado
            for(i = 0; i < selectedSector.streets.length; i++){
                var street = selectedSector.streets[i];
                var option = document.createElement('option');
                option.text = street.name;
                selectStreet.add(option);
            }

            setInfoSectorValues(selectedSector, selectedSector.streets[0], cardRules);
        });

        //Evento para troca de informações da tabela conforme rua
        selectStreet.addEventListener('change', function(){
            var street = null;

            //Encontra o setor selecionado
            var selectedStreetName = $('#via').find(':selected')[0].value;
            for(var i = 0; i < selectedSector.streets.length; i++){
                street = selectedSector.streets[i];
                if(street.name == selectedStreetName){
                    selectedStreet = street;
                    break;
                }
            }

            setInfoSectorValues(selectedSector, street, cardRules);
        });
    }
}

function scrollDown() {
    $(document).ready(function (){
        $("#howItWork").click(function (){
            $('html, body').animate({
                scrollTop: $("#goToEaseToUse").offset().top
            }, 1000);
        });
    });

    $(document).ready(function (){
        $("#ease").click(function (){
            $('html, section').animate({
                scrollTop: $("#easeUse").offset().top
            }, 1000);
        });
    });
}


window.onload = function(){
    getInfoSectorData();
    scrollDown();
}
